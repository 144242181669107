const dirtyWords = [
  "hentai",
  "oppai",
  "yaoi",
  "kuso,",
  "ecchi",
  "yuri",
  "shounen-ai",
  "eroge",
  "fan service,",
  "seme",
  "uke",
  "ahegao",
  "fudanshi",
  "fujoshi,",
  "futanari",
  "zettai ryoiki",
  "sugoi dekai",
  "mega milk",
];

export default dirtyWords;
