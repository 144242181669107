const cleanWords = [
  "kawaii",
  "otaku",
  "sugoi",
  "desu",
  "baka,",
  "Naruto",
  "Pokemon",
  "onii-chan,",
  "daijobu",
  "tsundere",
  "senpai notice me",
  "yare yare",
  "nani",
  "sensei",
  "manga",
  "chibi",
  "moe;",
  "anime",
  "yandere",
  "onee-san",
  "doujinshi",
  "neko",
  "ne",
  "itai",
  "nya",
  "shoujo",
  "meganekko,",
  "bishounen",
  "hikikomori",
  "waifu",
  "imouto",
  "otouto",
  "husbando,",
  "yatta",
  "arigatou",
  "sumimasen",
  "gomenasai",
  "suki",
  "hontou",
  "oishii",
  "bishoujo",
  "seiyuu",
  "kami-sama",
  "dakimakura",
  "gaijin",
  "tomodachi,",
  "ohayou gozaimasu",
  "oi",
  "jaa ne",
  "kowai",
  "itadakimasu",
  "nihon",
  "nihongo,",
  "dame",
  "doki doki",
  "right in the kokoro,",
  "kakkoii",
  "kanashii;",
  "kareshi",
  "sou desu ne",
  "mecha,",
  "minna",
  "onegai",
  "Shonen Jump,",
  "sushi",
  "ramen",
  "yokatta",
  "One Piece",
  "shonen",
  "cosplay",
  "weeaboo",
  "kohai",
  "filler",
  "genki",
  "magical girl",
  "aishiteru",
  "bakemono",
  "chotto",
  "doko",
  "ano,",
  "Attack on Titan",
  "kitsune",
  "konnichiwa",
  "watashi",
  "pocky",
  "ramune",
  "it's not like I like you or anything b-baka",
  "Death Note",
  "mangaka",
  "dandere;",
  "harem",
  "aho",
  "kimochi",
  "white day",
  "honmei choco,",
  "ENG SUB",
  "AMV",
  "hai",
  "josei",
  "OVA",
  "gakuran",
  "J-pop",
  "J-rock",
  "lolita fashion",
  "gyaru",
  "visual kei",
  "zettai",
  "isekai",
  "katana",
  "kuudere",
  "nijikon",
  "otome game,",
  "urusai",
  "Boku no Hero Academia",
  "maid cafe",
  "kanji",
  "Studio Ghibli",
  "takoyaki",
  "kimono",
  "hiragana",
  "katakana",
  "JoJo's Bizarre Adventure",
  "Hare Hare Yukai",
  "Caramelldansen;",
  "anime convention",
  "crunchyroll",
  "JRPG",
  "uwu",
  "ORA ORA ORA",
  "WRYYY",
  "Hatsune Miku,",
  "Cowboy Bebop",
  "Ouran High School Host Club",
  "Fullmetal Alchemist",
  "samurai",
  "pikachu",
  "taiyaki",
  "onigiri",
  "omurice",
  "katsudon",
  "Bleach",
  "kono Dio da",
  "hololive,",
  "VTubers",
  "Demon Slayer",
  "Inuyasha",
  "Dragon Ball Z",
  "Fairy Tail",
  "Sailor Moon",
];

export default cleanWords;